import React, { useState } from "react"
import axios from "axios"
import styled from "styled-components"

const ContactForm = () => {
  const [name, setName] = useState("")
  const [company, setCompany] = useState("")
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [question, setQuestion] = useState("")

  const [messageOK, setMessageOK] = useState("")
  const [sendingMessage, setSendingMessage] = useState("")
  const [showMessage, setShowMessage] = useState(false)

  const handleSubmit = async event => {
    event.preventDefault()

    try {
      setShowMessage(true)
      setSendingMessage("Sending message....")
      await axios.post(
        "https://us-central1-karlanastrego-form.cloudfunctions.net/contactForm",
        {
          name,
          company,
          email,
          phoneNumber,
          message: question,
        }
      )
      setSendingMessage("")
      setMessageOK("Your message is sent successfully")
      setTimeout(() => {
        setMessageOK("")
        setShowMessage(false)
      }, 2000)
    } catch (error) {
      setMessageOK("Something went wrong")
      setTimeout(() => {
        setMessageOK("")
        setShowMessage(false)
      }, 2000)
    }

    setName("")
    setCompany("")
    setEmail("")
    setPhoneNumber("")
    setQuestion("")
  }

  return (
    <DivFormWrapper>
      <h1 style={{ textAlign: "center", margin: "1.5rem 0 1rem 0" }}>
        Get in touch
      </h1>
      <FormWrapper>
        <StyledForm onSubmit={handleSubmit}>
          <div className="name-input">
            <input
              type="text"
              placeholder="Your name"
              required
              name="name"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </div>
          <div className="company-input">
            <input
              type="text"
              placeholder="Company"
              name="company"
              value={company}
              onChange={e => setCompany(e.target.value)}
            />
          </div>
          <div className="email-input">
            <input
              type="email"
              placeholder="Your email"
              required
              name="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div className="phone-number-input">
            <input
              type="text"
              placeholder="Your phone number"
              name="phone-number"
              value={phoneNumber}
              onChange={e => setPhoneNumber(e.target.value)}
            />
          </div>
          <div className="question">
            <div>
              <textarea
                placeholder="Your question/message"
                required
                name="question-text"
                value={question}
                onChange={e => setQuestion(e.target.value)}
              ></textarea>
            </div>
            <button className="blue-button" type="submit">
              Submit
            </button>
          </div>
        </StyledForm>
      </FormWrapper>

      {showMessage && (
        <div className={messageOK ? "overlay out" : "overlay in"}>
          <div className="message">
            {messageOK ? (
              <h4 className="ok">{messageOK}</h4>
            ) : (
              <h4 className="sending">{sendingMessage}</h4>
            )}
          </div>
        </div>
      )}
    </DivFormWrapper>
  )
}

const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
`

const StyledForm = styled.form`
  button {
    width: 100%;
  }

  @media screen and (min-width: 576px) {
    display: grid;
    grid-template-columns: auto auto;

    .question {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    textarea {
      width: 100%;
    }

    .name-input,
    .email-input {
      padding-right: 10px;
    }
  }
`

const DivFormWrapper = styled.div`
  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 2;
  }

  .in {
    animation-duration: 0.5s;
    animation-name: fadeoverlayin;
  }

  .out {
    animation-duration: 0.5s;
    animation-delay: 1.6s;
    animation-name: fadeoverlayout;
  }

  .message {
    position: fixed;
    top: 40vh;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: white;
  }

  @media screen and (min-width: 576px) {
    .message {
      width: 50%;
      border-radius: 4px;
      left: 45vh;
    }
  }

  h4 {
    text-align: center;
    color: green;
    margin: 0;
  }

  .ok {
    animation-duration: 1s;
    animation-name: fadeok;
  }

  .sending {
    animation-duration: 1s;
    animation-name: fadesend;
  }

  @keyframes fadesend {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fadeok {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fadeoverlayin {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fadeoverlayout {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }
`

export default ContactForm
