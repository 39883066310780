import React from "react"
import styled from "styled-components"

const Skills = () => {
  return (
    <ListWrapper>
      <ul>
        <li>Javascript</li>
        <li>TypeScript</li>
        <li>HTML/(S)CSS</li>
        <li>Node.js</li>
        <li>RESTful API's</li>
      </ul>
      <ul>
        <li>React</li>
        <li>Gatsby</li>
        <li>CICD</li>
        <li>D3.js</li>
        <li>SQL</li>
      </ul>
      <ul>
        <li>MongoDB</li>
        <li>GraphQL</li>
        <li>Git</li>
        <li>Docker</li>
        <li>Kubernetes</li>
      </ul>
      <ul>
        <li>Google Cloud</li>
        <li>TensorFlow</li>
        <li>Java</li>
        <li>Express</li>
        <li>Socket.io</li>
      </ul>
    </ListWrapper>
  )
}

const ListWrapper = styled.div`
  @media screen and (min-width: 576px) {
    display: flex;
    flex-direction: row;

    justify-content: center;

    ul {
      li {
        font-size: 1.5rem;
      }
    }
  }

  display: grid;
  grid-template-columns: auto auto;

  background-color: white;

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    font-size: 1.2rem;
    font-weight: lighter;
    padding: 0.2rem;
    margin: 0 1rem 0 1rem;
  }
`

export default Skills
