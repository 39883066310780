import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ContactForm from "../components/contact-form"
import Skills from "../components/skills"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <DivWrapper>
      <h3>I have expertise in the following technologies</h3>
      <Skills />
    </DivWrapper>

    <ContactForm />
  </Layout>
)

const DivWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 2rem 0 2rem 0;

  @media screen and (min-width: 576px) {
  }

  h3 {
    margin: 0;
    padding: 0 5px 0 5px;
    font-size: 2rem;
    text-align: center;
  }
`

export default IndexPage
